/* eslint-disable no-undef */
import { arrowRight, plaidIcon } from 'assets/img';
import { Direction } from 'components/Button';
import { useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import sessionStorage from 'store/storages/sessionStorage';
import { SESSION_KEY } from 'const';
import api, { endpoints } from 'api';
import { useHistory } from 'react-router';
import paths from 'routes/paths';
import { notification } from 'antd';

const plaidEnv = process.env.REACT_APP_PLAID_ENV;
const plaidClient = process.env.REACT_APP_PLAID_CLIENT;

function PlaidPay({ token, showLoader }) {
    const history = useHistory();

    const makePayment = useCallback(
        async (paymentData) => {
            try {
                showLoader(true);
                const req = await api({
                    method: 'post',
                    url: endpoints.ACH_PAYMENT,
                    data: paymentData,
                });

                const res = await req.data;

                showLoader(false);

                if (res['success'] && res.success === true) {
                    history.push(paths.SUCCESS, {
                        message: res.message,
                    });
                }
            } catch (error) {
                showLoader(false);
                notification.error({
                    message: 'Payment Failed',
                    description:
                        'Sorry we could not process your payment request! Please try again!',
                });
            }
        },
        [history, showLoader]
    );

    const onSuccess = useCallback(
        (token, metadata) => {
            const payId = sessionStorage.read(SESSION_KEY.pay_id);

            const paymentData = {
                fp_uid: payId,
                plaid_account_id: metadata.account_id,
                plaid_token: token,
                account_name: metadata.account.name,
            };

            makePayment(paymentData);
        },
        [makePayment]
    );

    const config = {
        token,
        onSuccess,
        selectAccount: true,
        product: ['auth', 'identity', 'transactions'],
        env: plaidEnv,
        clientName: plaidClient,
    };

    const { open, ready } = usePlaidLink(config);

    return (
        <Direction
            title="Pay with Plaid"
            iconRight={arrowRight}
            iconLeft={plaidIcon}
            onClick={() => open()}
            disabled={!ready}
        />
    );
}

export default PlaidPay;
