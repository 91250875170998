import { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import { TextInput, FormattedInput } from 'components/FormFields';
import { Col, Row } from 'components/Layout';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AmountModal from './AmountModal';
import { toUsCurrency } from 'lib';
import api, { endpoints } from 'api';
import Alert from 'components/Alert';
import * as Sentry from '@sentry/react';
import Space from 'components/Space';
import paths from 'routes/paths';
import { PageHeader } from 'components/Headers';
import Divider from 'components/Divider';
import sessionStorage from 'store/storages/sessionStorage';
import { SESSION_KEY } from 'const';

const initialValue = {
    credit_card: '',
    cc_expire: '',
    cvv: '',
};

function Payment(props) {
    const [caseDetails, setCaseDetails] = useState({
        id: '',
        name: '',
        list: [],
    });
    const [loading, showLoading] = useState(true);
    const [amountData, setAmountData] = useState({});
    const [errorMessage, showError] = useState({ visible: false, message: '' });

    const { case_id } = useParams();
    const history = useHistory();

    useEffect(() => {
        const fetchAmounts = async () => {
            try {
                const req = await api.get(
                    `${endpoints.PAYMENT_CHECK}${case_id}/`
                );
                const res = await req.data;

                setCaseDetails(res);

                // Set id to session storage for other payment method
                sessionStorage.write(SESSION_KEY.pay_id, res.id);

                // Add user to Sentry
                Sentry.setUser({ id: res.id, username: res.name });

                showLoading(false);
            } catch (e) {
                showLoading(false);
                console.log(e);
            }
        };

        if (case_id) {
            // Set case id to session storage for other payment method
            sessionStorage.write(SESSION_KEY.case_id, case_id);
            fetchAmounts();
        }
    }, [case_id]);

    const addPayment = async (values) => {
        // Hide error if shows earlier
        showError({ visible: false, message: '' });

        try {
            // Convert card number from string to integer.
            const credit_card = parseInt(
                values.credit_card.replace(/\s+/g, ''),
                10
            );

            const data = {
                ...values,
                amount: amountData.value,
                method: amountData.key,
                uid: caseDetails.id,
                credit_card,
                gateway: 'vyapay',
            };

            const req = await api({
                method: 'post',
                url: `${endpoints.SEND_PAYMENT}${case_id}/`,
                data,
            });

            const res = req.data;

            const { success, message } = res;

            if (success) {
                history.push('/success', { message });
            } else {
                showError({
                    visible: true,
                    message:
                        message && typeof message === 'string'
                            ? message
                            : 'Please try again',
                });
            }
        } catch (e) {
            if (e.response && e.response['data']) {
                const { detail } = e.response['data'];

                showError({
                    visible: true,
                    message:
                        detail && typeof detail === 'string'
                            ? detail
                            : 'Please give accurate info!',
                });
            }
        }
    };

    const setAmount = (amountData) => {
        setAmountData(amountData);

        // Set amount and method to session storage for other payment method
        sessionStorage.write(SESSION_KEY.amount, amountData.value);
        sessionStorage.write(SESSION_KEY.method, amountData.key);
    };

    return (
        <Fragment>
            <PageHeader
                heading="Card Verification"
                description="At TaxRise, we take your security seriously. We authenticate
                your payment information to prevent fraud and protect your
                identity."
            />

            <Divider />
            <h1>{caseDetails.name}</h1>
            <h3>Case ID: {case_id}</h3>
            <Divider />
            <PaySection>
                <h3>Amount Due</h3>
                <h1>{toUsCurrency(amountData.value)}</h1>
            </PaySection>

            <Formik
                initialValues={initialValue}
                onSubmit={addPayment}
                enableReinitialize
                validateOnMount
                validationSchema={validationRules}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <Form>
                        <FormattedInput
                            label="Card Number"
                            name="credit_card"
                            options={{ creditCard: true }}
                        />
                        <Row gutter={16} className="mb-16">
                            <Col span={12}>
                                <FormattedInput
                                    label="Expiration Date"
                                    name="cc_expire"
                                    options={{
                                        date: true,
                                        datePattern: ['m', 'y'],
                                    }}
                                />
                            </Col>
                            <Col span={12}>
                                <TextInput
                                    label="CVV"
                                    name="cvv"
                                    type="password"
                                />
                            </Col>
                        </Row>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Button
                                htmlType="submit"
                                block
                                type="primary"
                                onClick={handleSubmit}
                                loading={isSubmitting}
                            >
                                Verify Payment
                            </Button>
                            <Button block type="primary" color="#616161">
                                <Link to={paths.PAYMENT_OPTIONS}>
                                    Other Payment Options
                                </Link>
                            </Button>
                        </Space>
                    </Form>
                )}
            </Formik>

            {errorMessage.visible && (
                <Alert
                    message={errorMessage.message}
                    type="error"
                    className="mt-16"
                    closable
                />
            )}

            <AmountModal
                caseDetails={caseDetails}
                setAmount={setAmount}
                loading={loading}
            />
        </Fragment>
    );
}

const validationRules = Yup.object().shape({
    credit_card: Yup.string()
        .required('Please give your credit card number')
        .test('valid-card', 'Invalid card number', (value) => {
            if (/[^0-9-\s]+/.test(value)) return false;

            // The Luhn Algorithm
            let nCheck = 0;
            let bEven = false;
            value = value.replace(/\D/g, '');

            for (let n = value.length - 1; n >= 0; n--) {
                const cDigit = value.charAt(n);
                let nDigit = parseInt(cDigit, 10);

                if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

                nCheck += nDigit;
                bEven = !bEven;
            }

            return nCheck % 10 == 0;
        }),
    cc_expire: Yup.string()
        .required('Please give card expire date')
        .test('is-before', 'Invalid date', (value) => {
            // Date format is correct
            if (/^(0[1-9]|1[0-2])\/([0-9]{2})$/g.test(value)) {
                // Split value to date and year, exDateArr[0] is month and exDateArr[1] is year
                const exDateArr = value.split('/');

                // Check expiry date
                const today = new Date();

                const yearCentury = (today.getFullYear() / 100) | (0 + '');
                if (
                    new Date(yearCentury + exDateArr[1], exDateArr[0], 1) <
                    today
                ) {
                    return false;
                }

                return true;
            } else {
                return false;
            }
        }),
    cvv: Yup.string()
        .required('Please give card CVV')
        .matches(/^[0-9]{3,4}$/, 'Invalid CVV'),
});

const PaySection = styled.section`
    text-align: center;
    font-weight: 600;

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 16px;
    }
`;

export default Payment;
