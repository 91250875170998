import Payment from 'pages/Payment';
import PaymentOptions, {
    BankDraft,
    PayPalCancel,
    PayPalRedirect,
} from 'pages/PaymentOptions';
import AmazonCallback from 'pages/PaymentOptions/AmazonCallback';
import { PaymentSuccess } from 'pages/Result';
import { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import paths from './paths';
// import store from 'store';

const Routes = () => {
    return (
        <Switch>
            <Route exact path={paths.AMAZON_REDIRECT}>
                <AmazonCallback />
            </Route>
            <Route exact path={paths.PAYPAL_CANCEL}>
                <PayPalCancel />
            </Route>
            <Route exact path={paths.PAYPAL_REDIRECT}>
                <PayPalRedirect />
            </Route>
            <Route exact path={paths.BANKDRAFT}>
                <BankDraft />
            </Route>
            <Route exact path={paths.PAYMENT_OPTIONS}>
                <PaymentOptions />
            </Route>
            <Route exact path={paths.SUCCESS}>
                <PaymentSuccess />
            </Route>
            <Route exact path="/:case_id">
                <Payment />
            </Route>
            <Route path={paths.HOME}>
                <Fragment>
                    <h1>Welcome</h1>
                    <p>The url seems incorrect! Please try the exact url</p>
                </Fragment>
            </Route>
            <Route path="*">
                <PageNotFound />
            </Route>
        </Switch>
    );
};

/* function PrivateRoute({ children, ...rest }) {
    const access_token = store.get('access_token');

    return (
        <Route
            {...rest}
            render={({ location }) =>
                access_token ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
} */

function PageNotFound() {
    return (
        <Fragment>
            <h1>Page Not Found</h1>
        </Fragment>
    );
}

export default Routes;
