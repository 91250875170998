import Divider from 'components/Divider';
import { PageHeader } from 'components/Headers';
import Space from 'components/Space';
import { SESSION_KEY } from 'const';
import { toUsCurrency } from 'lib';
import { useEffect, useState } from 'react';
import sessionStorage from 'store/storages/sessionStorage';
import PlaidPay from './PlaidPay';
import api, { endpoints } from 'api';
import Spinner from 'components/Loader';
import { notification } from 'antd';

function BankDraft(props) {
    const [plaidToken, setPlaidToken] = useState('');
    const [loading, toggleLoading] = useState(true);

    const caseId = sessionStorage.read(SESSION_KEY.case_id);
    const amount = sessionStorage.read(SESSION_KEY.amount);

    useEffect(() => {
        const method = sessionStorage.read(SESSION_KEY.method);
        const payId = sessionStorage.read(SESSION_KEY.pay_id);
        const fetchPlaidToken = async () => {
            try {
                const req = await api({
                    method: 'post',
                    url: endpoints.PLAID_TOKEN,
                    data: {
                        case_id: caseId,
                        fp_uid: payId,
                        method,
                        amount,
                    },
                });

                const res = await req.data;

                setPlaidToken(res.link_token);
                toggleLoading(false);
            } catch (error) {
                toggleLoading(false);

                notification.error({
                    message: `Something went wrong!`,
                    description: (
                        <p>
                            Sorry, we can&apos;t process your request. Please
                            contact with us.
                        </p>
                    ),
                });
            }
        };

        if (amount && caseId && method && payId) {
            fetchPlaidToken();
        }
    }, [amount, caseId]);

    if (!caseId || !amount) {
        return (
            <h3 className="text-warning">
                Your payment information are missing. Please start again! <br />
                Thank you!
            </h3>
        );
    }

    return (
        <Spinner spinning={loading}>
            <PageHeader heading="Bankdraft (ACH)" showBackButton />

            <Divider />
            <h2>Case ID: {caseId}</h2>
            <h2>Amount: {toUsCurrency(amount)}</h2>
            <Divider />

            <Space direction="vertical" style={{ width: '100%' }} size={15}>
                {plaidToken && (
                    <PlaidPay token={plaidToken} showLoader={toggleLoading} />
                )}
            </Space>
        </Spinner>
    );
}

export default BankDraft;
