const HOME = '/';
const PAYMENT_OPTIONS = '/payment-options/';
const BANKDRAFT = `${PAYMENT_OPTIONS}bankdraft/`;
const SUCCESS = '/success';
const PAYPAL_REDIRECT = '/paypal/redirect/';
const PAYPAL_CANCEL = '/paypal/cancel/';
const AMAZON_REDIRECT = '/amazon/redirect/';

export default {
    HOME,
    PAYMENT_OPTIONS,
    BANKDRAFT,
    SUCCESS,
    PAYPAL_REDIRECT,
    PAYPAL_CANCEL,
    AMAZON_REDIRECT,
};
